import request from '@/utils/request'

// 查询称号配置列表
export function listAppellation(query) {
  return request({
    url: '/config/appellation/list',
    method: 'get',
    params: query
  })
}

// 查询称号配置详细
export function getAppellation(id) {
  return request({
    url: '/config/appellation/' + id,
    method: 'get'
  })
}
// 查询徽章分组下拉列表
export function loadFilterOptionsGroup(query) {
  return request({
    url: '/config/appellation/listGroup',
    method: 'get',
    params: query
  })
}
// 新增称号配置
export function addAppellation(data) {
  return request({
    url: '/config/appellation',
    method: 'post',
    data: data
  })
}

// 修改称号配置
export function updateAppellation(data) {
  return request({
    url: '/config/appellation',
    method: 'put',
    data: data
  })
}

// 删除称号配置
export function delAppellation(id) {
  return request({
    url: '/config/appellation/' + id,
    method: 'delete'
  })
}

/**
 * 获取获得称号的用户列表
 * @param {String} appellationId 称号id
 */
export function userAppellation(params) {
  return request({
    url: '/user/user/appellation/user-appellation-list',
    method: 'get',
    params: params
  })
}

// 发放称号
export function send(data) {
  return request({
    url: '/user/user/appellation/user-send',
    method: 'post',
    data: data
  })
}


// 回收称号
export function revoke(data) {
  return request({
    url: '/user/user/appellation/user-revoke',
    method: 'post',
    data: data
  })
}
