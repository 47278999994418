<template>
  <div>
    <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <!-- <a-form-model-item label="发放用户" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容"  allow-clear/>
        <a-input v-model="form.uid" placeholder="用户id"  allow-clear/>
        <a @click="handleInfo()">获取发放称号的用户</a>
      </a-form-model-item> -->
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="称号类型" prop="type">
          <a-select style="width: 100%" v-model="form.type" placeholder="请选择称号类型" @change="changeType">
            <a-select-option v-for="(item, index) in this.customDict.TitleTypeEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--      <a-form-model-item label="活动称号类型 1:普通称号,2:星河称号" prop="actType" >
              </a-form-model-item>-->
        <a-form-model-item label="称号名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入称号名称"/>
        </a-form-model-item>
        <a-form-model-item label="称号分组" prop="sort" v-if="isShow1">
          <a-select v-model="form.sort" style="width: 100%" placeholder="请选择分组" :options="ConfigBadgeTypeOptionsGroup"
                    @change="selectFilterConfigBadgeTypeChangeGroup"></a-select>
        </a-form-model-item>
        <a-form-model-item label="级别" prop="level" v-if="isShow">
          <!--          <a-input v-model="form.level" placeholder="请输入级别"/>-->
          <!--          <a-input v-model="form.level" placeholder="请输入级别" oninput="value=value.replace(/[^0-9.]/g,'')" />-->
          <a-input-number v-model:value="form.level" :min="0" :max="9999" :precision="0"/>
        </a-form-model-item>
        <a-form-model-item label="称号图片" prop="img">
          <a-upload
            name="img"
            listType="picture-card"
            class="avatar-uploader"
            :multiple="true"
            :show-upload-list="false"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="imgAvatarUpload">
            <img
              v-if="form.img"
              :src="form.img"
              alt="img"
              style="height: 102px; width: 102px; border-radius: 50%"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item label="未点亮图片" prop="notLightImg">
          <a-upload
            name="notLightImg"
            listType="picture-card"
            class="avatar-uploader"
            :multiple="true"
            :show-upload-list="false"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="notimgAvatarUpload">
            <img
              v-if="form.notLightImg"
              :src="form.notLightImg"
              alt="notLightImg"
              style="height: 102px; width: 102px; border-radius: 50%"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="达成条件描述" prop="reach">
          <a-input v-model="form.reach" placeholder="请输入达成条件"/>
        </a-form-model-item>
        <!--      <a-form-model-item label="达成条件" prop="conditionId">
                <a-input v-model="form.conditionId" placeholder="请输入达成条件"/>
              </a-form-model-item>-->
        <a-form-model-item label="标语" prop="slogan">
          <a-input v-model="form.slogan" placeholder="请输入标语"/>
        </a-form-model-item>
        <a-form-model-item label="升1级所需的组局数量" prop="groupNum" v-if="isShow1">
          <!--          <a-input v-model="form.groupNum" placeholder="请输入升1级所需的组局数量"/>-->
          <a-input-number v-model:value="form.groupNum" :min="0" :max="9999" :precision="0" placeholder="请输入组局数量"/>
        </a-form-model-item>
        <a-form-model-item label="是否绝版" prop="isOut">
          <a-switch v-model="form.isOut" />{{ form.isOut ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="是否顶级" prop="top">
          <a-switch v-model="form.top" />{{ form.top ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="剧本类型" prop="badgeReachFilter" v-if="isShow2">
          <api-select v-model="form.badgeReachFilter" placeholder="剧本类型"
                      api="/script/script/filter/select-option?type=2" multi
                      :searchKeys="['name']">
            <template v-slot="{ option }">
              <span>{{ option.name }}</span>
            </template>
          </api-select>
        </a-form-model-item>

        <!--      <a-form-model-item label="逻辑删除标记 1:删除,0:未删除" prop="isDeleted" v-if="formType === 1">
                <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记 1:删除,0:未删除" />
              </a-form-model-item>-->
        <!--        <a-form-model-item label="备注" prop="remark">-->
        <!--          <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear/>-->
        <!--        </a-form-model-item>-->
        <!--      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
                <a-input v-model="form.version" placeholder="请输入乐观锁" />
              </a-form-model-item>-->
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer>
    <index-user-modal ref="IndexUserModal" @select="indexScriptModalSelect" @selectname="indexScriptModalName">>
    </index-user-modal>
  </div>
</template>

<script>
import {getAppellation, addAppellation, updateAppellation,loadFilterOptionsGroup} from '@/api/config/appellation'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from '@/api/tool/alioss'
import IndexUserModal from "@/views/user/user/modules/IndexUserModal";
import ApiSelect from "@/views/config/badge/modules/ApiSelect.vue";

export default {
  name: 'CreateForm',
  props: {},
  components: {ApiSelect, IndexUserModal},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      isShow: true,
      isShow1: true,
      isShow2: true,
      ConfigBadgeTypeOptionsGroup: [],
      // 表单参数
      form: {
        id: null,

        type: null,

        actType: null,

        uid: null,
        sort: null,
        name: null,

        level: null,

        img: null,
        notLightImg: null,
        conditionId: null,
        reach: null,
        slogan: null,

        groupNum: null,

        isEnable: null,
        alone: null,
        top: null,
        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,
        badgeReachFilter: null,
        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          {required: true, message: '称号类型', trigger: 'change'}
        ],

        actType: [
          {required: true, message: '活动称号类型 1:普通称号,2:星河称号不能为空', trigger: 'change'}
        ],

        name: [
          {required: true, message: '称号名称不能为空', trigger: 'change'}
        ],
        // sort: [
        //   {required: true, message: '徽章分类不能为空', trigger: 'blur'}
        // ],
        level: [
          {required: true, message: '级别数,一共多少级别不能为空', trigger: 'blur'}
        ],
        groupNum: [
          {required: true, message: '级别数,一共多少级别不能为空', trigger: 'blur'}
        ],

        img: [
          {required: true, message: '称号图片不能为空', trigger: 'blur'}
        ],
        notLightImg: [
          {required: true, message: '称号图片不能为空', trigger: 'blur'}
        ],
        conditionId: [
          {required: true, message: '达成条件不能为空', trigger: 'blur'}
        ],

        slogan: [
          {required: true, message: 'slogan不能为空', trigger: 'blur'}
        ],

        isEnable: [
          {required: true, message: '是否绝版不能为空', trigger: 'blur'}
        ],
        top: [
          {required: true, message: '是否顶级不能为空', trigger: 'blur'}
        ]

        // isDeleted: [
        //   {required: true, message: '逻辑删除标记 1:删除,0:未删除不能为空', trigger: 'blur'}
        // ],

        // version: [
        //   {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        // ]

      }
    }
  },
  filters: {},
  created() {
    this.initConfigBadgeTypeOptionsGroup()

  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.isShow = true
      this.isShow1 = true
      this.isShow2 = true
      this.form = {
        id: null,

        type: null,
        sort: null,

        actType: null,

        uid: null,

        name: null,

        level: null,

        img: null,
        notLightImg: null,
        conditionId: null,
        reach: null,
        slogan: null,

        groupNum: null,

        isEnable: null,
        alone: null,
        top: null,
        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,
        badgeReachFilter: [],
        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.isShow = null
      this.isShow1 = null
      this.isShow2 = null
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    selectFilterConfigBadgeTypeChangeGroup(value) {
      this.form.sort = value
    },
    initConfigBadgeTypeOptionsGroup() {
      loadFilterOptionsGroup({type: 4}).then(res => {
        this.ConfigBadgeTypeOptionsGroup = res.data
      })
    },
    indexScriptModalSelect(ids) {
      if (Array.isArray(ids)) {
        this.$set(this.form, 'uid', ids.join())
      } else {
        this.$set(this.form, 'uid', ids)
      }
      // this.$set(this.form, 'uid',ids)
      console.log("获取到的ids", ids);
    },
    indexScriptModalName(name) {
      console.log("获取到的names", name);
      if (Array.isArray(name)) {
        this.$set(this.form, 'remark', name.join())
      } else {
        this.$set(this.form, 'remark', name)
      }
    },
    handleInfo: function (id) {
      this.$refs.IndexUserModal.open();
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      debugger
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAppellation(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAppellation(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAppellation(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    changeType(value) {
      console.log("123" + value);
      this.isShow = false;
      if (value == 1) {
        this.isShow = true;
        this.isShow1 = true;
        this.isShow2 = true;
      }
      if (value == 2) {
        this.isShow = true;
        this.isShow1 = true;
        this.isShow2 = false;
      }
      if (value == 3) {
        this.isShow = false;
        this.isShow1 = false;
        this.isShow2 = false;
      }
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    imgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cover'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'img', res)
          _this.$message.success('上传成功')
        })
      })
    },
    notimgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'notLightImg'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'notLightImg', res)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束

  }
}
</script>
